import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import tw from "twin.macro"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Layout from "../layouts/base"
import SEO from "../components/seo"

const Wrapper = tw.div``

const SitePageTemplate = ({ data: { page } }) => {
	const featuredImage = {
		fluid: page.featuredImage?.node?.localFile?.childImageSharp?.fluid,
		alt: page.featuredImage?.node?.alt || ``,
	}

	return (
		<Layout>
			<SEO title={page.seo?.metaTitle ?? page.title} description={page.seo.metaDescription} />

			<main className="container" tw="py-12 md:py-16 xl:py-20">
				{/* if we have a featured image for this page let's display it */}
				{featuredImage?.fluid && (
					<div tw="mb-4 md:mb-8 lg:mb-12">
						<Image tw="rounded-xl" fluid={featuredImage.fluid} alt={featuredImage.alt} />
					</div>
				)}

				<h1>{parse(page.title)}</h1>

				{!!page.content && <div className="prose">{parse(page.content)}</div>}
			</main>
		</Layout>
	)
}

export default SitePageTemplate

export const pageQuery = graphql`
	query SitePageById(
		# these variables are passed in via createPage.pageContext in gatsby-node.js
		$id: String!
	) {
		# selecting the current page by id
		page: wpPage(id: { eq: $id }) {
			id
			content
			title
			seo {
				metaTitle
				metaDescription
			}

			featuredImage {
				node {
					altText
					localFile {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
			}
		}
	}
`
